import React from 'react'
import './Rocket.scss'

function RocketContainer(props) {
    return (
        <div className={`rocket ${props.className}`}>
            {
                // <p className="rocket-title">{props.title}</p>
                // <p className="rocket-content">{props.content}</p>
            }
            <p className="rocket-title"><span className="main-title">{props.title}</span>
            <div style={{display: 'inline-flex'}}><div className="dot"></div><span>{props.content}</span></div>
            </p>
            <p className="rocket-content">{props.context}</p>
        </div>
    )
}

export default RocketContainer
