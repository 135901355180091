import React from 'react'
import './SideBar.scss'

function Small(props) {
    return (
        <div className={`small ${props.selected ? 'on-select' : ''}`}></div>
    )
}

function Large(props) {
    return (
        <div className={`large ${props.selected ? 'on-select' : ''}`}></div>
    )
}

function Side(props) {
    const {currentPos} = props
    return (
        <div className="side">
            <Small selected={currentPos === 0 ? true : false} />
            <Small selected={currentPos === 1 ? true : false} />
            <Large selected={currentPos === 2 ? true : false} />
            <Small selected={currentPos === 3 ? true : false} />
            <Small selected={currentPos === 4 ? true : false} />
            <Small selected={currentPos === 5 ? true : false} />
        </div>
    )
}

export default Side
