import React from 'react';
import ReactDOM from 'react-dom'
import Swipe from 'react-easy-swipe';
import ReactSwipe from 'react-swipe';
// import logo from './logo.png';
import logoWhite from './images/logo-white.png';
import './App.scss';
import Bar from './components/Bar'
// import Services from './pages/Services'
import { CSSTransition } from 'react-transition-group';
import logo from './images/logo-color.png'
import chairImg from './images/index/chair.png'
import trustImg from './images/index/trust1.png'
import partnerImg from './images/index/partner.png'
import mailImg from './images/contact-bg.png'
import qrCode from './images/qrcode.jpg'
import Side from './components/SideBar'
// import narrowImg from './images/narrow.png'
import triLeftImg from './images/left.png'
import triRightImg from './images/right.png'
import backImg from './images/back-icon.png'

import listenBgImg from './images/index/listen-bg.jpg'
import seenBgImg from './images/index/seen-bg.jpg'
import practiceBgImg from './images/index/practice-bg.jpg'
import usIconsImg from './images/index/us-icons.png'

import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import RocketContainer from './components/Rocket'

const is_IE = (ver) => {
    let b = document.createElement('b')
    b.innerHTML = '<!--[if IE ' + ver + ']><i></i><![endif]-->'
    return b.getElementsByTagName('i').length === 1
}

const isLowIE = () => {
    return is_IE(9) || is_IE(8) || is_IE(7) || is_IE(6) || is_IE(5)
}

var docEl = document.documentElement
const setFontSize = function () {
    var clientWidth = document.documentElement.clientWidth
        // timer;
    if (!clientWidth) return;
    //原本对于html的字号设置 我这里用的是750的标准 ，640的也是同理
    docEl.style.fontSize = 12 * (clientWidth / 1920) + 'px';


    if (window.getComputedStyle(document.getElementsByTagName("html")[0]).fontSize) {
        var size = window.getComputedStyle(document.getElementsByTagName("html")[0]).fontSize.split('p')[0];

        //这里再取出当前html的font-size，和你想附的值进行比较，
        //这里为什么不直接用size< 原因是正常机型的size也不一定等于你想附的值，尝试了很多机型，除了问题机型外，一般差距很小，另一半完全相等;
        //为什么不直接*1.25 也不解释了 最终比例不一定是1.25
        if (size * 1.2 < 12 * (clientWidth / 1920)) {
            //如果当前html的font-size 的1.2倍仍然小于 之前想设置的值，就说明是问题机型，给之前想附的值乘1.25倍，这样他会被系统再次除1.25得到的才是我们想附的值
            docEl.style.fontSize = (12 * 1.25) * (clientWidth / 1920) + 'px';
        }
    }
};
setFontSize()

window.addEventListener('resize', () => {
    console.log('resize')
    setFontSize()
})


function Contact(props) {
    return (
        <div className={`contact-container ${props.showIn ? 'contact-change' : ''}`}>
            {
                props.show ?
                    <div className="bubble" >
                        <div className="greeting">
                            <p>感谢您对阿基米德</p>
                            <p>的关注!</p>
                        </div>
                        <div className="qr-container">
                            <img src={qrCode} />
                            <p>扫描关注</p>
                            <p>阿基米德公众号</p>
                        </div>
                        <div className="divider"></div>
                        <div className="info" >
                            <div className="people content">
                                <span>刘博典</span>
                            </div>
                            <div className="mail content">
                                <a href="mailto:liubodian@amed.net">liubodian@amed.net</a>
                            </div>
                            <div className="phone content">
                                <span>13701227393</span>
                            </div>
                        </div>
                    </div>
                    : null
            }
            {
                props.showIn ?
                    <img className="back-btn" onClick={props.back} height="auto" src={backImg} />
                    : null
            }
            <img height="auto" onClick={props.click} src={mailImg} />
        </div>
    )
}


function Home(props) {
    return (
        <div className="App-header" id="home">
            {
                isLowIE() ?
                    <div className="content">
                        <p className="title">专注于</p>
                        <p className="cc">从洞察到践行的实现法则</p>
                        <p className="content-en en-one" >Focus on the realization principle</p>
                        <p className="content-en">from insight to practice</p>
                    </div>
                    : <CSSTransition
                        in={props.showTextType === 'home'}
                        classNames='text'
                        timeout={300}
                        unmountOnExit>
                        <div className="content">
                            <p className="title">专注于</p>
                            <p className="cc">从洞察到践行的实现法则</p>
                            <p className="content-en en-one" >Focus on the realization principle</p>
                            <p className="content-en">from insight to practice</p>
                        </div>
                    </CSSTransition>
            }
            <div style={{ color: '#9a9696', position: 'absolute', bottom: 0, width: '100%' }}>
                <p style={{ textAlign: 'center' }}>
                    Copyright © 2013 - 2020 amed.net All Rights Reversed.<a style={{ color: '#9a9696', textDecoration: 'none' }} href="http://www.beian.miit.gov.cn/">京ICP备15005891号-1</a>
                </p>
            </div>
        </div>
    )
}

function Services(props) {
    return (
        <div id="research">
            <div className="services-container">
                {
                    isLowIE() ?
                        <div className="content">
                            <p className="title">调研与洞察</p>
                            <p className="title-en">Research and Insight</p>
                            <p className="cc">专注于医疗健康领域，以深厚的行业知识、经验为我们的客户提供独到的商业视角与解决思路，驱动精准的市场营销，共同应对复杂的挑战。</p>
                        </div>
                        : <CSSTransition
                            in={props.showTextType === 'service'}
                            classNames='text'
                            timeout={300}
                            unmountOnExit>
                            <div className="content">
                                <p className="title">调研与洞察</p>
                                <p className="title-en">Research and Insight</p>
                                <p className="cc">专注于医疗健康领域，以深厚的行业知识、经验为我们的客户提供独到的商业视角与解决思路，驱动精准的市场营销，共同应对复杂的挑战。</p>
                            </div>
                        </CSSTransition>
                }
            </div>
        </div>
    )
}

class Field extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div id="field" style={{ overflow: 'hidden', width: '600%', height: '100vh' }}>
                <div className="field-container">
                    {
                        isLowIE() ?
                            <div className="content" onClick={this.props.click} style={{ zIndex: 100 }}>
                                <p className="title">服务领域</p>
                                <p className="title-en">Service Area</p>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                    <p className="btn"></p>
                                </div>
                            </div>
                            : <CSSTransition
                                in={this.props.showTextType === 'field'}
                                classNames='mobiletext'
                                timeout={300}
                                unmountOnExit>
                                <div className="content" onClick={this.props.click} style={{ zIndex: '100' }}>
                                    <p className="title">服务领域</p>
                                    <p className="title-en">Service Area</p>
                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                        <p className="btn"></p>
                                    </div>
                                </div>
                            </CSSTransition>
                    }
                </div>
                <Listen show={this.props.showRocketType === 'listen'} />
                <Seen show={this.props.showRocketType === 'seen'} />
                <Innovate show={this.props.showRocketType === 'innovate'} />
                <Practice show={this.props.showRocketType === 'practice'} />
                <Trust show={this.props.showRocketType === 'trust'} />
            </div>
        )
    }
}

function Resp(props) {
    return (
        <div id="resp">
            <div className="resp-container">
                {
                    isLowIE() ?
                        <div className="content">
                            <p className="title">社会责任</p>
                            <p className="title-en">Social Responsibility</p>
                            <p className="cc">实现员工的可持续发展、帮助大众提高健康水平、对社会承担责任， 是我们坚定的承诺。</p>
                        </div>
                        : <CSSTransition
                            in={props.showTextType === 'resp'}
                            classNames='righttext'
                            timeout={300}
                            unmountOnExit>
                            <div className="content">
                                <p className="title">社会责任</p>
                                <p className="title-en">Social Responsibility</p>
                                <p className="cc">实现员工的可持续发展、帮助大众提高健康水平、对社会承担责任， 是我们坚定的承诺。</p>
                            </div>
                        </CSSTransition>
                }
            </div>
        </div>
    )
}

function Us(props) {
    return (
        <div id="us">
            <div className="us-container">
                {
                    isLowIE() ?
                        <div className="content">
                            <p className="title">关于我们</p>
                            <p className="title-en">About Us</p>
                            <p className="cc">AMED服务于中国医疗领域提供企业数字化营销、风险投资及慢病管理。我们拥有深厚的行业资源以及践行经验，逐渐成为医疗行业的资源交换中心。深刻的洞察和优质的服务正在帮助着政府团体、医疗机构、资本机构以及致力于健康医疗事业的经济体建立信任和信心。</p>
                        </div>
                        : <CSSTransition
                            in={props.showTextType === 'us'}
                            classNames='text'
                            timeout={300}
                            unmountOnExit>
                            <div className="content">
                                <p className="title">关于我们</p>
                                <p className="title-en">About Us</p>
                                <p className="cc">AMED服务于中国医疗领域提供企业数字化营销、风险投资及慢病管理。我们拥有深厚的行业资源以及践行经验，逐渐成为医疗行业的资源交换中心。深刻的洞察和优质的服务正在帮助着政府团体、医疗机构、资本机构以及致力于健康医疗事业的经济体建立信任和信心。</p>
                            </div>
                        </CSSTransition>
                }
                <img className="icons-container" src={usIconsImg} />
            </div>
        </div>
    )
}

function Partner(props) {
    return (
        <div id="partner">
            <div className="partner-container">
                <img src={partnerImg} className="p" />
                {
                    isLowIE() ?
                        <div className="content">
                            <p className="title">我们的伙伴</p>
                            <p className="title-en">Our Partners</p>
                        </div>
                        : <CSSTransition
                            in={props.showTextType === 'partner'}
                            classNames='text'
                            timeout={300}
                            unmountOnExit>
                            <div className="content">
                                <p className="title">我们的伙伴</p>
                                <p className="title-en">Our Partners</p>
                            </div>
                        </CSSTransition>
                }
            </div>
        </div>
    )
}

class Listen extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
        }
    }

    render() {
        return (
            <div onClick={this.click} id="listen" >
                <div className="listen-container">
                    {
                        isLowIE() ? <div style={{ width: '100%', height: '100vh', }}>
                            <RocketContainer className="listen-rocket" title="聆听" content="用心响应期待"
                                context="我们与医疗健康融为一体，生命与行业共鸣。我们坚信，以客户为导向、以用户为中心、以客户反馈为准则，是保持战略和行动一致性的前提条件。收集市场声音，聆听您的表达，是我们给您最真诚的承诺。"
                            />
                            <img className="listen-bg" src={listenBgImg} />
                        </div> : <CSSTransition
                            in={this.props.show}
                            classNames='show'
                            timeout={300}
                            unmountOnExit>
                                <div style={{ width: '100%', height: '100vh', }}>
                                    <RocketContainer className="listen-rocket" title="聆听" content="用心响应期待"
                                        context="我们与医疗健康融为一体，生命与行业共鸣。我们坚信，以客户为导向、以用户为中心、以客户反馈为准则，是保持战略和行动一致性的前提条件。收集市场声音，聆听您的表达，是我们给您最真诚的承诺。"
                                    />
                                    <img className="listen-bg" src={listenBgImg} />
                                </div>
                            </CSSTransition>
                    }
                </div>
            </div>
        )
    }
}

class Seen extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div id="seen" >
                <div className="seen-container">
                    {
                        isLowIE() ?
                            <RocketContainer className="seen-rocket" title="洞察" content="洞见前所未见"
                                context="深耕医疗健康领域，与学术领袖和行业顶级协会合作，帮助客户驾驭不确定性并根据需要灵活应变，生产具有影响力的内容，从而在快速发展的竞争中占据重要地位，我们根据知识而非猜测来策划客户的数字化营销体系，用于创造突破性的可持续成果，帮助客户取得成功。"
                            />
                            : <CSSTransition
                                in={this.props.show}
                                classNames='show'
                                timeout={300}
                                unmountOnExit>
                                <RocketContainer className="seen-rocket" title="洞察" content="洞见前所未见"
                                    context="深耕医疗健康领域，与学术领袖和行业顶级协会合作，帮助客户驾驭不确定性并根据需要灵活应变，生产具有影响力的内容，从而在快速发展的竞争中占据重要地位，我们根据知识而非猜测来策划客户的数字化营销体系，用于创造突破性的可持续成果，帮助客户取得成功。"
                                />
                            </CSSTransition>
                    }
                    {
                        isLowIE() ?
                            <img className="seen-bg" src={seenBgImg} />
                            : <CSSTransition
                                in={this.props.show}
                                classNames='text'
                                timeout={300}
                                unmountOnExit>
                                <img className="seen-bg" src={seenBgImg} />
                            </CSSTransition>

                    }
                </div>
            </div>
        )
    }
}

class Innovate extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div id="innovate" onFocus={this.handleFocus}>
                <div className="innovate-container">
                    {
                        isLowIE() ?
                            <img className="chair" src={chairImg} />
                            : <CSSTransition
                                in={this.props.show}
                                classNames='righttext'
                                timeout={300}
                                unmountOnExit>
                                <img className="chair" src={chairImg} />
                            </CSSTransition>
                    }
                    {
                        isLowIE() ?
                            <RocketContainer className="innovate-rocket" title="创新" content="岂止点到为止"
                                context="我们相信，大胆的想法和可行的见识能够改变思想，形成辩论并激发行动。没有固定的答案，只有针对特定挑战的解决方案。帮助客户从容应对数字化的艰巨挑战，与现有业务并驾齐驱，通过建构未来框架、战略加速和能力支持来释放强有力的商业价值。"
                            />
                            : <CSSTransition
                                in={this.props.show}
                                classNames='show'
                                timeout={300}
                                unmountOnExit>
                                <RocketContainer className="innovate-rocket" title="创新" content="岂止点到为止"
                                    context="我们相信，大胆的想法和可行的见识能够改变思想，形成辩论并激发行动。没有固定的答案，只有针对特定挑战的解决方案。帮助客户从容应对数字化的艰巨挑战，与现有业务并驾齐驱，通过建构未来框架、战略加速和能力支持来释放强有力的商业价值。"
                                />
                            </CSSTransition>
                    }
                </div>
            </div>
        )
    }
}

class Trust extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div id="trust" onFocus={this.handleFocus}>
                <div className="trust-container">
                    {
                        isLowIE() ?
                            <img className="trust" src={trustImg} />
                            : <CSSTransition
                                in={this.props.show}
                                classNames='righttext'
                                timeout={300}
                                unmountOnExit>
                                <img className="trust" src={trustImg} />
                            </CSSTransition>
                    }
                    {
                        isLowIE() ?
                            <RocketContainer className="trust-rocket" title="信任" content="携手成就共赢"
                                context="对我们而言，以高度诚信开展业务至关重要。在瞬息万变的法规、合规和商业环境中，我们始终以最适当、符合道德和透明的方式开展业务，帮助客户创造可持续的内涵式增长，重构价值客户管理体系，探索价值医疗创新之道。"
                            />
                            : <CSSTransition
                                in={this.props.show}
                                classNames='show'
                                timeout={300}
                                unmountOnExit>
                                <RocketContainer className="trust-rocket" title="信任" content="携手成就共赢"
                                    context="对我们而言，以高度诚信开展业务至关重要。在瞬息万变的法规、合规和商业环境中，我们始终以最适当、符合道德和透明的方式开展业务，帮助客户创造可持续的内涵式增长，重构价值客户管理体系，探索价值医疗创新之道。"
                                />
                            </CSSTransition>
                    }
                </div>
            </div>
        )
    }
}

class Practice extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div id="practice" onFocus={this.handleFocus}>
                <div className="practice-container">
                    {
                        isLowIE() ?
                            <img className="practice-bg" src={practiceBgImg} />
                            : <CSSTransition
                                in={this.props.show}
                                classNames='text'
                                timeout={300}
                                unmountOnExit>
                                <img className="practice-bg" src={practiceBgImg} />
                            </CSSTransition>
                    }
                    {
                        isLowIE() ?
                            <RocketContainer className="practice-rocket" title="践行" content="继续展现价值"
                                context="为项目匹配最优的团队组合，帮助客户品牌定制营销策略、学术策略、推广活动、新媒体传播等方案并快速、敏捷、精益地执行，对进行中的项目进行动态追踪与全局评估，掌控细节并根据反馈进行调整，将敏锐战略有效的转化为可盈利的增长。"
                            />
                            : <CSSTransition
                                in={this.props.show}
                                classNames='show'
                                timeout={300}
                                unmountOnExit>
                                <RocketContainer className="practice-rocket" title="践行" content="继续展现价值"
                                    context="为项目匹配最优的团队组合，帮助客户品牌定制营销策略、学术策略、推广活动、新媒体传播等方案并快速、敏捷、精益地执行，对进行中的项目进行动态追踪与全局评估，掌控细节并根据反馈进行调整，将敏锐战略有效的转化为可盈利的增长。"
                                />
                            </CSSTransition>
                    }
                </div>
            </div>
        )
    }
}


class App extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            viewStyle: {
                // width: '100%',
                // transform: 'translate3d(0, 0, 0)',
                // position: 'relative',
            },
            moveRange: 0,
            outList: [
                {
                    component: <Home />,
                    class: 'scroll-home',
                    unClass: 'scroll-home-un',
                    logo: 'white',
                    fontColor: '#ffffff',
                },
                {
                    component: <Services />,
                    class: 'scroll-research',
                    unClass: 'scroll-research-un',
                    logo: 'color',
                    fontColor: '#004a9f',
                },
                {
                    component: <Field />,
                    class: 'scroll-field',
                    unClass: 'scroll-field-un',
                    logo: 'white',
                    fontColor: '#ffffff',
                },
                {
                    component: <Resp />,
                    class: 'scroll-resp',
                    unClass: 'scroll-resp-un',
                    logo: 'white',
                    fontColor: '#e4030d',
                },
                {
                    component: <Us />,
                    class: 'scroll-us',
                    unClass: 'scroll-us-un',
                    logo: 'white',
                    fontColor: '#fff',
                },
                {
                    component: <Partner />,
                    class: 'scroll-partner',
                    unClass: 'scroll-partner-un',
                    logo: 'color',
                    fontColor: '#004ba0',
                },
            ],
            inList: [
                {
                    component: <Listen />,
                    class: 'scroll-listen',
                    logo: 'white',
                    fontColor: '#ffffff',
                },
                {
                    component: <Seen />,
                    class: 'scroll-seen',
                    logo: 'white',
                    fontColor: '#3ba7fe',
                },
                {
                    component: <Innovate />,
                    class: 'scroll-innovate',
                    logo: 'color',
                    fontColor: '#1d2f39',
                },
                {
                    component: <Practice />,
                    class: 'scroll-practice',
                    logo: 'white',
                    fontColor: '#d52d1c',
                },
                {
                    component: <Trust />,
                    class: 'scroll-trust',
                    logo: 'color',
                    fontColor: '#969696',
                },
            ],
            currentPos: 0,
            currentFieldPos: 9,
            logoColor: 'white',
            fontColor: '#ffffff',
            move: '',
            scrollStatus: false,
            show: false,
            showIn: false,
            showRocketType: '',
            showInfoBubble: false,
            showTextType: '',
            showTop: true,
            touchMove: false,
            inTouchMove: false,
        }
    }

    whichTransitionEvent() {
        var t,
            el = document.getElementById('App'),
            transitions = {
                'transition': 'transitionend',
                'OTransition': 'oTransitionEnd',
                'MozTransition': 'transitionend',
                'WebkitTransition': 'webkitTransitionEnd',
                'MsTransition': 'MsTransitionEnd',
            }

        for (t in transitions) {
            if (el.style[t] !== undefined) {
                return transitions[t];
            }
        }
    }

    launchRocket = () => {
        const { currentFieldPos, inList } = this.state
        let target = inList[this.state.currentFieldPos]
        let type = ''
        switch (currentFieldPos) {
            case 0:
                type = 'listen'
                break;
            case 1:
                type = 'seen'
                break;
            case 2:
                type = 'innovate'
                break;
            case 3:
                type = 'practice'
                break;
            case 4:
                type = 'trust'
                break;
            default:
                type = ''
        }
        this.setState({
            showRocketType: type,
            showTextType: '',
            logoColor: target['logo'],
            fontColor: target['fontColor'],
            showTop: true,
        })
    }

    launchText = () => {
        const { currentPos, outList } = this.state
        let target = outList[currentPos]
        let type = ''
        switch (currentPos) {
            case 0:
                type = 'home'
                break;
            case 1:
                type = 'service'
                break;
            case 2:
                type = 'field'
                break;
            case 3:
                type = 'resp'
                break;
            case 4:
                type = 'us'
                break;
            case 5:
                type = 'partner'
                break;
            default:
                type = ''
        }
        this.setState({
            showTextType: type,
            showRocketType: '',
            logoColor: target['logo'],
            fontColor: target['fontColor'],
            showTop: true,
        })
    }

    componentDidMount() {
        let event = this.whichTransitionEvent()
        let appEle = document.getElementById('App')
        appEle.addEventListener(event, () => {
            this.setState({
                scrollStatus: false,
            })
            const { showIn } = this.state
            if (showIn) {
                this.launchRocket()
            } else {
                this.launchText()
            }
        }, false);

        const input = document.getElementById('App');

        const wheelScroll$ = fromEvent(input, 'wheel')

        const debouncedInput = wheelScroll$.pipe(debounceTime(200));

        const subscribe = debouncedInput.subscribe(event => {
            this.handleScroll(event)
        });
        setTimeout(() => {
            this.setState({
                showTextType: 'home',
            })
        }, 500)
        setFontSize()
    }

    handleScroll = async (e) => {
        const { scrollStatus } = this.state
        if (scrollStatus) {
            return
        }
        this.setState({
            scrollStatus: true
        })
        setTimeout(() => {
            this.setState({
                scrollStatus: false
            })
        }, 1000)
        try {
            await this.processScroll(e)
        } catch (e) {
            console.error(e)
        }
    }

    scrollOut = (e) => {
        e.preventDefault()
        return new Promise((resolve, reject) => {
            const ele = ReactDOM.findDOMNode(this);
            const { currentPos, outList } = this.state
            if (e.deltaY <= 0) {
                /* scrolling up */
                if (ele.scrollTop <= 0) {
                    if (currentPos === 0) {
                        return
                    }
                    let target = outList[currentPos - 1];
                    this.setState({
                        move: target['class'],
                        currentPos: currentPos - 1,
                        showTop: false,
                    })
                    resolve()
                    return
                }
            } else {
                if (currentPos === (outList.length - 1)) {
                    return
                }
                let target = outList[currentPos + 1]
                this.setState({
                    move: target['class'],
                    currentPos: currentPos + 1,
                    showTop: false,
                })
                resolve()
                return
            }
        })
    }

    scrollIn = (e) => {
        e.preventDefault()
        return new Promise((resolve, reject) => {
            const ele = ReactDOM.findDOMNode(this);
            const { currentFieldPos, inList } = this.state
            if (e.deltaY <= 0) {
                /* scrolling up */
                if (ele.scrollTop <= 0) {
                    this.handleScrollIn(currentFieldPos - 1)
                    resolve()
                }
            } else {
                this.handleScrollIn(currentFieldPos + 1)
                resolve()
            }
        })
    }

    handleScrollIn = (targetIndex) => {
        const { inList } = this.state
        if (targetIndex < 0 || targetIndex >= inList.length) {
            return
        }
        let target = inList[targetIndex];
        this.setState({
            move: target['class'],
            currentFieldPos: targetIndex,
            showTop: false,
        })
    }

    processScroll = async (e) => {
        e.preventDefault();
        const { showIn } = this.state
        if (showIn) {
            await this.scrollIn(e)
        } else {
            await this.scrollOut(e)
        }
    }

    click = (e) => {
        e.preventDefault()
        const { currentPos, inList, outList, showIn } = this.state
        if (currentPos === 2 && showIn === false) {
            let target = inList[0]
            this.setState({
                move: inList[0]['class'],
                showIn: true,
                currentFieldPos: 0,
                fontColor: target['fontColor'],
                logoColor: target['logo'],
                showTop: true,
            })
        } else if (currentPos === 2 && showIn === true) {
            let target = outList[2]
            this.setState({
                showIn: false,
                move: target['unClass'],
                currentFieldPos: 0,
                currentPos: 2,
                fontColor: target['fontColor'],
                logoColor: target['logo'],
                showTop: true,
            })
            this.launchText()
        }
    }

    jumpFromTopBar = (index) => {
        const { outList, showIn: showInFlag, currentPos } = this.state
        if (showInFlag === false && index === currentPos) {
            return
        }
        let classIndex = 'class'
        let showIn = false
        let target = outList[index]
        if (this.state.showIn) {
            classIndex = 'unClass'
            showIn = false
        }
        let targetState = {
            showIn: showIn,
            move: target[classIndex],
            currentFieldPos: 0,
            currentPos: index,
            showTop: false,
        }
        let flag = this.state.showIn
        if (flag) {
            targetState['logoColor'] = target['logo']
            targetState['fontColor'] = target['fontColor']
        }
        this.setState({
            ...targetState,
        }, () => {
            if (flag) {
                this.launchText()
            }
        })
    }

    toggleInfoBubble = (e) => {
        e.preventDefault()
        this.setState({
            showInfoBubble: !this.state.showInfoBubble
        })
    }

    calculateBase = () => {
        return 100 / this.state.outList.length
    }

    calculateInBase = () => {
        return 100 / (this.state.inList.length + 1)
    }

    onSwipeStart = (event) => {
        event.preventDefault()
        if (this.state.showIn) {
            this.setState({
                inTouchMove: true,
            })
            return
        }
        this.setState({
            touchMove: true,
        })
    }

    onSwipeMove = (position, event) => {
        event.preventDefault()
        if (this.state.showIn && this.state.inTouchMove) {
            this.handleSwipeIn(position)
            return
        }
        if (this.state.showIn) {
            return
        }
        if (!this.state.touchMove) {
            return
        }
        if (this.state.currentPos === 0 && position.y > 0) {
            return
        }
        if (this.state.currentPos === this.state.outList.length - 1 && position.y < 0) {
            return
        }
        const { viewStyle } = this.state
        const percentage = -(this.state.currentPos * this.calculateBase()) + position.y / (document.body.clientHeight * this.state.outList.length) * 100
        if ((Math.abs(position.y) / document.body.clientHeight * 100) >= 20) {
            return
        }
        this.setState({
            viewStyle: {
                ...viewStyle,
                transform: `translate3d(0, ${percentage}%, 0)`,
                WebkitTransform: `translate3d(0, ${percentage}%, 0)`,
                transition: 'all 0',
            },
            moveRange: position.y,
        })
    }

    handleSwipeIn = (position) => {
        const { currentFieldPos, viewStyle, inList } = this.state
        if (currentFieldPos === 0 && position.x > 0) {
            return
        }
        if (currentFieldPos === inList.length - 1 && position.x < 0) {
            return
        }
        const offset = position.x / document.body.clientWidth * 100
        const basePercentage = -(currentFieldPos + 1) * 100
        const percentage = basePercentage + offset
        if ((Math.abs(position.x) / document.body.clientWidth * 100) >= 20) {
            return
        }
        this.setState({
            viewStyle: {
                ...viewStyle,
                transform: `translate3d(${percentage}%, ${-100 / 6 * 2}%, 0)`,
                WebkitTransform: `translate3d(${percentage}%, ${-100 / 6 * 2}%, 0)`,
                transition: 'all 0',
            },
            moveRange: position.x,
        })
    }

    handleSwipeInEnd = (moveRange) => {
        const { currentFieldPos } = this.state
        if (moveRange > 0) {
            this.handleScrollIn(currentFieldPos - 1)
        } else if (moveRange < 0) {
            this.handleScrollIn(currentFieldPos + 1)
        }
    }

    onSwipeEnd = (e) => {
        // e.preventDefault()
        if (this.state.showIn) {
            this.handleSwipeInEnd(this.state.moveRange)
            this.setState({
                viewStyle: {},
                touchMove: false,
                inTouchMove: false,
                moveRange: 0,
            })
            return
        }
        if (this.state.moveRange === 0) {
            return
        }
        const { moveRange, currentPos, outList } = this.state
        const moveFlag = moveRange > 0 ? true : false
        this.setState({
            viewStyle: {},
            touchMove: false,
            inTouchMove: false,
            moveRange: 0,
        })
        if (moveFlag) {
            currentPos - 1 >= 0 && this.jumpFromTopBar(currentPos - 1)
        } else {
            currentPos + 1 < outList.length && this.jumpFromTopBar(currentPos + 1)
        }
    }


    render() {
        const { logoColor, fontColor, showIn, currentFieldPos, currentPos, showRocketType, showInfoBubble, showTextType, inList } = this.state
        return (
            <div id="App" onTouchMove={this.handleTouchMove} onTouchStart={this.handleTouchStart} onTouchEnd={this.handleTouchEnd} className={`App `} style={{ height: '100vh', overflow: 'hidden' }}>
                {
                    showIn && currentFieldPos > 0 ?
                        <img onClick={e => this.handleScrollIn(currentFieldPos - 1)} className="triangle-left" src={triLeftImg} />
                        : null
                }
                {
                    showIn && currentFieldPos < inList.length - 1 ?
                        <img onClick={e => this.handleScrollIn(currentFieldPos + 1)} className="triangle-right" src={triRightImg} />
                        : null
                }
                {
                    isLowIE() ?
                        <Bar color={fontColor} currentPos={currentPos} jump={this.jumpFromTopBar} />
                        : <CSSTransition
                            in={this.state.showTop}
                            classNames='fadeshow'
                            timeout={300}
                            unmountOnExit>
                            <Bar color={fontColor} showTop={this.state.showTop} currentPos={currentPos} jump={this.jumpFromTopBar} />
                        </CSSTransition>
                }
                <div onClick={this.toggleInfoBubble} style={{ position: 'absolute', display: showInfoBubble ? 'block' : 'none', width: '100%', height: '100vh', zIndex: '10', left: 0, top: 0 }}></div>
                {
                    isLowIE() ? <div className="logo" onClick={this.click}>
                        {
                            logoColor === 'white' ?
                                <img height="auto" src={logoWhite} /> :
                                <img height="auto" src={logo} />
                        }
                    </div>
                        : <CSSTransition
                            in={this.state.showTop}
                            classNames='fadeshow'
                            timeout={300}
                            unmountOnExit>
                            <div className="logo" onClick={this.click}>
                                {
                                    logoColor === 'white' ?
                                        <img height="auto" src={logoWhite} /> :
                                        <img height="auto" src={logo} />
                                }
                            </div>
                        </CSSTransition>
                }
                <Contact showIn={showIn} back={this.click} show={showInfoBubble} click={this.toggleInfoBubble} />
                <Side currentPos={currentPos} />
                <Swipe
                    onSwipeStart={this.onSwipeStart}
                    onSwipeMove={this.onSwipeMove}
                    onSwipeEnd={this.onSwipeEnd}
                >
                    <div className={this.state.move} style={this.state.viewStyle}>
                        <Home showTextType={showTextType} />
                        <Services showTextType={showTextType} />
                        <Field showTextType={showTextType} showRocketType={showRocketType} showIn={showIn} currentFieldPos={currentFieldPos} currentPos={currentPos} click={this.click} />
                        <Resp showTextType={showTextType} />
                        <Us showTextType={showTextType} />
                        <Partner showTextType={showTextType} />
                    </div>
                </Swipe>
            </div>
        )
    }
}

export default App;
