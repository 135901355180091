import React from 'react';
import './Bar.scss'

function Bar(props) {
    return (
        <div className="bar-container" style={{color: props.color}}>
            <div className="homebtn-container btn-container" style={{borderBottom: props.currentPos === 0 && props.showTop ? `1px solid ${props.color}` : ''}} onClick={() => {props.jump(0)}}>
                <div className="btn-zh">
                    首页
                </div>
                <div className="btn-en">
                    Home
                </div>
            </div>
            <div className="reserch-container btn-container" style={{borderBottom: props.currentPos === 1 && props.showTop ? `1px solid ${props.color}` : ''}} onClick={() => {props.jump(1)}}>
                <div className="btn-zh">
                    调研与洞察
                </div>
                <div className="btn-en">
                    Research and Insight
                </div>
            </div>
            <div className="service-container btn-container" style={{borderBottom: props.currentPos === 2 && props.showTop ? `1px solid ${props.color}` : ''}} onClick={() => {props.jump(2)}}>
                <div className="btn-zh">
                    服务领域
                </div>
                <div className="btn-en">
                    Service Area
                </div>
            </div>
            <div className="responsibility-container btn-container" style={{borderBottom: props.currentPos === 3 && props.showTop ? `1px solid ${props.color}` : ''}} onClick={() => {props.jump(3)}}>
                <div className="btn-zh">
                    社会责任
                </div>
                <div className="btn-en">
                    social Responsibility
                </div>
            </div>
            <div className="about-container btn-container" style={{borderBottom: props.currentPos === 4 && props.showTop ? `1px solid ${props.color}` : ''}} onClick={() => {props.jump(4)}}>
                <div className="btn-zh">
                    关于我们
                </div>
                <div className="btn-en">
                    About Us
                </div>
            </div>
            <div className="partners-container btn-container" style={{borderBottom: props.currentPos === 5 && props.showTop ? `1px solid ${props.color}` : ''}} onClick={() => {props.jump(5)}}>
                <div className="btn-zh">
                    我们的伙伴
                </div>
                <div className="btn-en">
                    Our Partners
                </div>
            </div>
        </div>
    )
}

export default Bar
